<template>
  <header class="row -banner" id="banner">
    <div class="stars"></div>
    <div class="stars2"></div>
    <div class="stars3"></div>
    <div class="banner-wrapper">
      <h1 class="title">
        <span class="line">I'm <span class="name">Ovi</span></span>
        <span class="line"
          >Let's Build <span class="name">Effective</span> Websites
        </span>
      </h1>
      <a class="link" href="#main" title="Scroll to main part of page">
        <svg class="icon" viewBox="0 0 960 560">
          <path
            d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937
            c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937
            c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
          />
        </svg>
      </a>
    </div>
  </header>
</template>

<script>
export default {
  name: "Banner",
};
</script>

<style lang='sass' scoped>
@import '@/assets/_variables.sass'

//Made by @screenshake
//linkedin.com/in/saranshsinha

// n is number of stars required
@function multiple-box-shadow ($n)
  $value: '#{random(3000)}px #{random(3000)}px #FFF'
  @for $i from 2 through $n
    $value: '#{$value} , #{random(3000)}px #{random(3000)}px #FFF'

  @return unquote($value)

$shadows-small: multiple-box-shadow(1050)
$shadows-medium: multiple-box-shadow(300)
$shadows-big: multiple-box-shadow(150)

.row.-banner
  height: 100vh
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%)
  overflow: hidden

.stars
  width: 1px
  height: 1px
  background: transparent
  box-shadow: $shadows-small
  animation: animStar 50s linear infinite

  &:after
    content: " "
    position: absolute
    top: 2000px
    width: 1px
    height: 1px
    background: transparent
    box-shadow: $shadows-small

.stars2
  width: 2px
  height: 2px
  background: transparent
  box-shadow: $shadows-medium
  animation: animStar 100s linear infinite

  &:after
    content: " "
    position: absolute
    top: 2000px
    width: 2px
    height: 2px
    background: transparent
    box-shadow: $shadows-medium

.stars3
  width: 3px
  height: 3px
  background: transparent
  box-shadow: $shadows-big
  animation: animStar 150s linear infinite

  &:after
    content: " "
    position: absolute
    top: 2000px
    width: 3px
    height: 3px
    background: transparent
    box-shadow: $shadows-big

.banner-wrapper
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100%

  > .link
    margin-top: 30px

    > .icon
      fill: $c-bittersweet
      height: 60px
      width: 60px
      transition: $normal-transition

      &:hover
        transform: scale(1.3)

.title
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  color: #FFF
  text-align: center
  font-size: 50px
  letter-spacing: 10px
  margin: 0

  @media (max-width: 1200px)
    font-size: 45px

  @media (max-width: 800px)
    font-size: 40px

  @media (max-width: 500px)
    font-size: 35px

  .line
    line-height: 1.3

  .line + .line
    margin-top: 50px

  .name
    color: $c-bittersweet
</style>