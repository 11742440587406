<template>
  <article
    class="project-info"
    v-for="(project, index) in projects"
    :key="index"
    :data-project="project.project_id"
  >
    <div class="img-container">
      <lazy-component>
        <picture>
          <source :srcset="srcsetUrl('webp', project)" type="image/webp" />
          <source :srcset="srcsetUrl('png', project)" />
          <img
            class="image"
            :src="project.images.original"
            :alt="project.images.alt"
          />
        </picture>
      </lazy-component>
    </div>
    <h3 class="title">{{ project.title }}</h3>
    <div class="description">
      <p>
        {{ project.description }}
      </p>
      <ul class="skills-list">
        <li class="skill" v-if="project.frontend">Frontend only</li>
        <li class="skill" v-else>Full-stack project</li>
      </ul>
      <ul class="skills-list">
        <li class="skill" v-for="(skill, index) in project.skills" :key="index">
          {{ skill }}
        </li>
      </ul>
    </div>
    <a :href="project.url" class="primary-button btn">View Site</a>
  </article>
</template>

<script>
export default {
  name: "Projects",
  props: ["image"],
  data() {
    return {
      observer: "",
      projects: [
        {
          project_id: "spinneys",
          title: "Spinney's shopping/recipes/lifestyle",
          frontend: true,
          description:
            "Started the project from the beginning with the template provided by the company. I was working on the front end part stitching the template with wagtail/django back end, creating ajax calls, adding new features, optimizing the template. In addition to the coding part I had a responsibility to delegate tasks to other front-end developers and make sure the deadlines are met.",
          images: {
            original: require("@/assets/img/spinneys-original-1920.png"),
            alt: "Spinney's website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/spinneys-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/spinneys-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/spinneys-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/spinneys-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/spinneys-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/spinneys-original-480.png"),
                size: 480,
              },
            },
          },
          skills: ["html", "css/scss", "js/ES6", "jQuery", "wagtail"],
          url: "https://www.spinneys.com/en-ae/",
        },
        {
          project_id: "choithrams",
          title: "Choithrams online supermarket store",
          frontend: true,
          description:
            "My job was to fix product design issues on the front page and to improve 'add to cart' design and functionality on product pages.",
          images: {
            original: require("@/assets/img/choithrams-original-1920.png"),
            alt: "Choithrams website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/choithrams-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/choithrams-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/choithrams-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/choithrams-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/choithrams-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/choithrams-original-480.png"),
                size: 480,
              },
            },
          },
          skills: ["html", "css/scss", "js/ES6", "jQuery"],
          url: "https://www.choithrams.com/en/",
        },
        {
          project_id: "galleria",
          title: "Abu Dhabi's leading lifestyle destination",
          frontend: true,
          description:
            "I helped the team to improve the responsive design according to the client's specifications.",
          images: {
            original: require("@/assets/img/galleria-original-1920.png"),
            alt: "Galleria website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/galleria-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/galleria-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/galleria-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/galleria-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/galleria-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/galleria-original-480.png"),
                size: 480,
              },
            },
          },
          skills: ["html", "css/scss"],
          url: "https://www.thegalleria.ae/en/",
        },
        {
          project_id: "lietuvaskaito",
          title: "Annual reading festival in Lithuania",
          frontend: false,
          description:
            "My first project where I implemented back end and front end parts completely myself. The challenge was unique as I was also responsible for hosting the website on my server. The frontend part was done using vue.js. For the CMS, I chose Strapi, because it was a headless, open-source CMS built using Node.js. For the server I chose Nginx. Postgres was used for the database. The project was finished before the deadline.",
          images: {
            original: require("@/assets/img/lietuvaskaito-original-1920.png"),
            alt: "Lietuva skaito website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/lietuvaskaito-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/lietuvaskaito-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/lietuvaskaito-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/lietuvaskaito-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/lietuvaskaito-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/lietuvaskaito-original-480.png"),
                size: 480,
              },
            },
          },
          skills: [
            "vue.js",
            "html",
            "css/sass",
            "js/ES6",
            "backend",
            "strapi/node.js",
            "postgres",
          ],
          url: "https://lietuvaskaito.lt/",
        },
        {
          project_id: "semioad",
          title:
            "Semioad provides semiotic analysis of the various digital campaigns",
          frontend: false,
          description:
            "My second full-stack project. Frontend: Vue.js. Backend: Strapi CMS + Postgres. The design was provided. Website was hosted using Nginx server.",
          images: {
            original: require("@/assets/img/semioad-original-1920.png"),
            alt: "Semioad website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/semioad-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/semioad-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/semioad-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/semioad-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/semioad-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/semioad-original-480.png"),
                size: 480,
              },
            },
          },
          skills: [
            "vue.js",
            "html",
            "css/sass",
            "js/ES6",
            "backend",
            "strapi/node.js",
            "postgres",
          ],
          url: "https://www.semioad.lt/",
        },
        {
          project_id: "autohuh",
          title: "Selling high quality used vehicles",
          frontend: true,
          description:
            "I worked on the frontend part of this project. Converted the design to html + scss and stitched the templates with wagtail/django. The project was simple and finished on time.",
          images: {
            original: require("@/assets/img/autohuh-original-1920.png"),
            alt: "Autohuh website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/autohuh-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/autohuh-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/autohuh-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/autohuh-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/autohuh-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/autohuh-original-480.png"),
                size: 480,
              },
            },
          },
          skills: ["html", "css/scss", "js/ES6", "wagtail"],
          url: "https://www.autohuh.lt/",
        },
        {
          project_id: "autoamerics",
          title: "Pioneering and novel car sunshades for windshield solutions",
          frontend: true,
          description:
            "I worked on the frontend part of this project. Converted the design to html + scss and stitched the templates with wagtail/django.",
          images: {
            original: require("@/assets/img/autoamerics-original-1920.png"),
            alt: "Autoamerics website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/autoamerics-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/autoamerics-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/autoamerics-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/autoamerics-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/autoamerics-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/autoamerics-original-480.png"),
                size: 480,
              },
            },
          },
          skills: ["html", "css/scss", "js/ES6", "wagtail"],
          url: "https://www.autoamerics.com/",
        },
        {
          project_id: "fabriqblog",
          title: "Smart & healthy buildings, delivered",
          frontend: true,
          description:
            "Converted the design to html + scss and stitched the templates with wagtail/django.",
          images: {
            original: require("@/assets/img/fabriqblog-original-1920.png"),
            alt: "Fabriq Blog section front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/fabriqblog-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/fabriqblog-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/fabriqblog-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/fabriqblog-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/fabriqblog-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/fabriqblog-original-480.png"),
                size: 480,
              },
            },
          },
          skills: ["html", "css/scss", "js/ES6", "wagtail"],
          url: "https://fabriq.space/blog/",
        },
      ],
    };
  },
  methods: {
    srcsetUrl(type, item) {
      const format = item.images[type];
      const sizes = Object.keys(format);

      return sizes
        .map((image) => `${format[image].url} ${format[image].size}w`)
        .join(", ");
    },
  },
};
</script>

<style lang='sass'>
@import '@/assets/_variables.sass'

.project-info
  display: none
  flex-direction: column
  overflow-y: scroll
  height: 100%
  background: white
  border-radius: 5px
  padding: 20px

  &.active
    display: flex

  > .img-container
    position: relative
    width: 100%
    overflow: hidden
    padding-top: 56.25%

    .image
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      object-fit: cover
      object-position: center
      transition: $normal-transition

  > .title
    margin: 40px 0 20px
    font-size: 2rem
    word-break: break-word
    text-align: left

    @media (max-width: 850px)
      font-size: 1.75rem

    @media (max-width: 500px)
      font-size: 1.5rem

  > .description
    display: flex
    flex-direction: column

    > .tasks
      margin-bottom: 20px

      > .task
        color: $c-bittersweet
        font-weight: 700

      > .list
        > li
          margin-left: 25px

    > .skills-list
      list-style: none
      margin-bottom: 20px
      display: block
      clear: both

      &:first-of-type
        margin-top: 40px

      > .skill
        float: left
        font-size: 16px
        font-weight: 500
        padding: 5px
        margin: 0 5px 5px 0
        color: white
        background: $c-medium-turquoise

  > .primary-button
    margin-top: 30px
</style>