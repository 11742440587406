import { createApp } from 'vue'
import VueLazyloadNext from 'vue-lazyload-next'
import App from './App.vue'
import './registerServiceWorker'

createApp(App)
  .use(VueLazyloadNext, {
    observer: true,
    lazyComponent: true
  })
  .mount('#app')
