<template>
  <div
    class="full-overlay"
    :class="{ opened: isOpened }"
    @click="clickNav"
  ></div>
  <nav class="nav-row">
    <div class="main-nav">
      <button
        class="label"
        aria-label="button to open mobile navigation"
        :class="{ opened: isOpened }"
        @click="clickNav"
        type="button"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <ul class="navigation-list">
        <li class="item" @click="clickNav">
          <a href="#about" class="link scrollactive" data-id="about">About</a>
        </li>
        <li class="item" @click="clickNav">
          <a href="#services" class="link scrollactive" data-id="services"
            >Services</a
          >
        </li>
        <li class="item" @click="clickNav">
          <a href="#portfolio" class="link scrollactive" data-id="portfolio"
            >Portfolio</a
          >
        </li>
        <li class="item" @click="clickNav">
          <a href="#contact" class="link scrollactive" data-id="contact"
            >Contact</a
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      isOpened: false,
    };
  },
  emits: ["clicked"],
  methods: {
    clickNav() {
      const media_query = window.matchMedia("(max-width: 500px)");

      if (media_query.matches) {
        const nav_el = document.querySelector(".nav-row");
        const nav_position = nav_el.getBoundingClientRect().top;

        if (nav_position > 0) {
          nav_el.scrollIntoView();
        }

        this.isOpened = !this.isOpened;
        this.$emit("clicked");
      }
    },
  },
};
</script>

<style lang='sass' scoped>
@import '@/assets/_variables.sass'

.nav-row
  width: 100%
  background-color: $c-gunmetal
  position: -webkit-sticky
  position: sticky
  top: 0
  border-bottom: 2px solid $c-medium-turquoise
  z-index: 7

.main-nav
  width: 100%
  padding: 0 5vw
  max-width: 1500px
  margin: 0 auto

  @media only screen and (max-width: 500px)
    height: 47px

  > .label
    position: absolute
    height: 35px
    cursor: pointer
    width: 35px
    background: transparent
    right: 5vw
    top: 5px
    display: flex
    flex-direction: column
    padding: 5px
    border: none

    &.opened
      > span
        margin: 0

        &:nth-child(1)
          transform: translateY(10px) rotate(135deg)

        &:nth-child(2)
          opacity: 0

        &:nth-child(3)
          transform: translateY(0) rotate(-135deg)

    &.opened ~ .navigation-list
      max-height: 300px
      z-index: 1
      transition: $normal-transition

    @media only screen and (min-width: 501px)
      display: none

    span
      display: block
      width: 30px
      height: 5px
      background-color: $c-bittersweet
      margin-top: 5px
      border-radius: 2px
      transition: $normal-transition

      &:first-of-type
        margin-top: 0

      &:nth-of-type(2)
        opacity: 1

  > .navigation-list
    list-style: none outside none
    display: flex
    align-items: center
    height: 100%

    @media only screen and (max-width: 700px)
      justify-content: center

    @media only screen and (max-width: 500px)
      display: block
      max-height: 0
      position: absolute
      right: 0
      top: 0
      margin: 0
      padding: 0
      margin-top: 47px
      overflow-y: hidden
      transition: $normal-transition
      width: 100%
      background: $c-gunmetal
      height: auto

    > .item
      margin-right: 10px
      text-transform: uppercase

      @media only screen and (max-width: 500px)
        margin-right: 0

      &:last-of-type
        margin-right: 0

      > a
        padding: 10px
        font-weight: 500
        color: $c-apple-green
        display: inline-block

        @media only screen and (max-width: 500px)
          display: inline-block
          padding: 15px calc(5vw + 5px) 15px 10px
          width: 100%
          text-align: right

        &.active
          color: $c-medium-turquoise
          transition: color .3s ease

        &:hover
          color: $c-bittersweet
          transition: color .3s ease
</style>