<template>
  <div class="effective-info">
    <div class="some-row">
      <div class="left fade-in-slide-left animate">
        <span class="icon -fast">
          <svg viewBox="0 0 24 24">
            <path
              d="M 12 3 C 6.4666667 3 2 7.4666667 2 13 C 2 15.233333 2.7940331 17.26175 4 18.90625 L 5.59375 17.71875 C 4.5997169 16.36325 4 14.766667 4 13 C 4 8.5333333 7.5333333 5 12 5 C 16.466667 5 20 8.5333333 20 13 C 20 14.766667 19.400283 16.36325 18.40625 17.71875 L 20 18.90625 C 21.205967 17.26175 22 15.233333 22 13 C 22 7.4666667 17.533333 3 12 3 z M 15.6875 7.90625 L 12.15625 11.4375 C 12.101946 11.43193 12.055775 11.40625 12 11.40625 C 11.116344 11.40625 10.40625 12.116344 10.40625 13 C 10.40625 13.883656 11.116344 14.59375 12 14.59375 C 12.883656 14.59375 13.59375 13.883656 13.59375 13 C 13.59375 12.944225 13.56807 12.898054 13.5625 12.84375 L 17.09375 9.3125 L 15.6875 7.90625 z"
            />
          </svg>
        </span>
        <h3 class="title">Speed</h3>
      </div>
      <div class="middle"></div>
      <div class="right fade-in-slide-right animate">
        <p class="feature">
          A fast website is a key for good user experience, low bounce rate,
          more sales and better rankings.
        </p>
      </div>
    </div>
    <div class="some-row -right">
      <div class="left fade-in-slide-left animate">
        <span class="icon -user">
          <svg viewBox="0 0 980.000000 980.000000">
            <g
              transform="translate(0.000000,980.000000) scale(0.100000,-0.100000)"
            >
              <path
                stroke-width="2"
                stroke="black"
                d="M5311 9789 c-139 -24 -263 -89 -347 -182 -63 -70 -99 -135 -133 -242
                -34 -105 -48 -195 -61 -400 -13 -222 -58 -361 -210 -660 -92 -182 -164 -303
                -257 -431 -32 -45 -382 -464 -778 -932 l-720 -850 -3 104 -3 104 -1399 0
                -1400 0 0 -3150 0 -3150 1400 0 1400 0 0 311 c0 245 3 310 13 306 6 -3 94 -37
                194 -76 600 -232 1206 -398 1744 -476 422 -61 398 -60 1694 -60 1328 0 1266
                -3 1493 71 437 142 813 506 952 924 32 93 70 268 70 316 0 19 25 53 93 124
                214 225 342 469 394 750 24 129 24 423 0 529 l-16 73 43 52 c107 126 217 329
                264 491 45 152 57 242 56 435 0 206 -17 312 -75 477 -53 150 -155 329 -258
                453 l-29 35 15 75 c26 119 23 416 -5 547 -54 252 -155 453 -330 657 -221 256
                -512 432 -847 509 -87 20 -121 21 -947 24 -472 2 -858 6 -858 8 0 3 53 85 118
                182 115 172 221 340 275 433 44 76 122 268 171 419 113 352 142 766 75 1096
                -43 211 -138 443 -254 620 -163 247 -357 394 -611 462 -98 26 -102 27 -484 29
                -212 1 -409 -2 -439 -7z m569 -334 c291 -18 365 -39 497 -139 99 -75 171 -170
                251 -332 125 -252 170 -493 152 -811 -22 -383 -79 -559 -319 -984 -116 -207
                -188 -315 -460 -694 -110 -154 -201 -283 -201 -287 0 -5 510 -8 1133 -8 1230
                0 1225 0 1393 -56 328 -110 610 -380 725 -695 51 -139 62 -209 62 -379 1 -162
                -14 -255 -61 -378 l-20 -52 75 -72 c200 -193 306 -409 335 -685 22 -213 -16
                -433 -107 -619 -53 -109 -160 -251 -243 -323 l-59 -52 28 -83 c91 -264 70
                -593 -54 -841 -67 -136 -181 -271 -322 -384 l-70 -56 -1 -130 c0 -101 -5 -149
                -23 -215 -90 -343 -405 -659 -771 -774 -181 -58 -179 -58 -1435 -53 -1155 4
                -1193 5 -1495 43 -634 78 -1348 280 -1982 559 l-108 47 0 2275 0 2274 838 982
                c461 540 871 1023 911 1072 160 201 315 460 402 676 104 255 154 479 169 764
                11 200 24 250 85 310 47 47 94 66 225 89 160 29 171 29 450 11z m-3430 -6305
                l0 -2800 -1050 0 -1050 0 0 2800 0 2800 1050 0 1050 0 0 -2800z"
              />
            </g>
          </svg>
        </span>
        <h3 class="title">Well-designed</h3>
      </div>
      <div class="middle"></div>
      <div class="right fade-in-slide-right animate">
        <p class="feature">
          Intuitive navigation, clear calls to action, well-formatted content
          and other good design practices are followed to achieve higher
          conversion rates, convey a clear message and build trust.
        </p>
      </div>
    </div>
    <div class="some-row">
      <div class="left fade-in-slide-left animate">
        <span class="icon -secure">
          <svg viewBox="0 0 866.000000 980.000000">
            <g
              transform="translate(0.000000,980.000000) scale(0.100000,-0.100000)"
            >
              <path
                d="M4190 9794 c-320 -60 -367 -79 -1240 -509 -699 -344 -810 -396 -1100
                -510 -491 -194 -941 -333 -1578 -486 l-262 -64 0 -310 c0 -1280 245 -2610 686
                -3722 432 -1090 1027 -2024 1779 -2792 571 -583 1218 -1076 1783 -1360 l83
                -42 162 97 c413 246 766 507 1135 842 171 155 504 486 654 649 984 1075 1683
                2394 2053 3873 196 781 305 1668 305 2475 l0 291 -242 58 c-521 125 -921 243
                -1328 389 -430 155 -668 262 -1465 659 -706 351 -780 383 -1001 433 -90 21
                -153 28 -259 30 -77 2 -151 2 -165 -1z m422 -428 c94 -27 180 -67 748 -346
                300 -147 615 -300 700 -340 714 -334 1287 -541 1950 -704 102 -26 200 -53 219
                -62 l34 -15 -7 -172 c-37 -944 -148 -1677 -370 -2461 -255 -898 -597 -1644
                -1102 -2407 -492 -743 -1010 -1315 -1691 -1866 -237 -193 -730 -543 -763 -543
                -13 0 -206 116 -365 218 -225 145 -471 331 -700 527 -1231 1059 -2120 2526
                -2545 4200 -190 747 -282 1424 -316 2322 l-7 182 34 15 c19 9 117 36 219 61
                861 213 1448 446 2735 1087 413 205 549 267 669 303 77 24 91 25 291 21 154
                -3 225 -8 267 -20z"
              />
            </g>
          </svg>
        </span>
        <h3 class="title">Security</h3>
      </div>
      <div class="middle"></div>
      <div class="right fade-in-slide-right animate">
        <p class="feature">
          Web security consists of a web server configuration, policies of
          creating, storing and renewing passwords and well-designed client-side
          code.
        </p>
      </div>
    </div>
    <div class="some-row -right">
      <div class="left fade-in-slide-left animate">
        <span class="icon -seo">
          <svg viewBox="0 0 500 500">
            <path
              d="M380 190C380 294.86 294.86 380 190 380C85.14 380 0 294.86 0 190C0 85.14 85.14 0 190 0C294.86 0 380 85.14 380 190Z"
            ></path>
            <path
              d="M334.5 191.88C334.5 271.9 269.53 336.88 189.5 336.88C109.47 336.88 44.5 271.9 44.5 191.88C44.5 111.85 109.47 46.88 189.5 46.88C269.53 46.88 334.5 111.85 334.5 191.88Z"
              fill="#292f36"
            ></path>
            <path
              d="M282.99 330.23L329.49 284.01L498.26 453.78L451.76 500L282.99 330.23Z"
            ></path>
          </svg>
        </span>
        <h3 class="title">SEO</h3>
      </div>
      <div class="middle"></div>
      <div class="right fade-in-slide-right animate">
        <p class="feature">
          SEO optimized website employs all the on-site tactics and methods that
          help it to rank higher in search results.
        </p>
      </div>
    </div>
    <div class="some-row">
      <div class="left fade-in-slide-left animate">
        <span class="icon -responsive">
          <svg class="icon -responsive" viewBox="0 0 640 640">
            <path
              d="M326.19 0C337.24 0 346.19 8.95 346.19 20C346.19 144 346.19 496 346.19 620C346.19 631.05 337.24 640 326.19 640C260.95 640 85.24 640 20 640C8.95 640 0 631.05 0 620C0 496 0 144 0 20C0 8.95 8.95 0 20 0C85.24 0 260.95 0 326.19 0Z"
            ></path>
            <path
              d="M304.88 20.4C315.93 20.4 324.88 29.35 324.88 40.4C324.88 146.63 324.88 445.35 324.88 551.59C324.88 562.63 315.93 571.59 304.88 571.59C248.17 571.59 98.02 571.59 41.31 571.59C30.26 571.59 21.31 562.63 21.31 551.59C21.31 445.35 21.31 146.63 21.31 40.4C21.31 29.35 30.26 20.4 41.31 20.4C98.02 20.4 248.17 20.4 304.88 20.4Z"
              fill="#292f36"
            ></path>
            <path
              d="M620 170.99C631.05 170.99 640 179.95 640 190.99C640 244.85 640 386.42 640 440.28C640 451.32 631.05 460.28 620 460.28C508.33 460.28 193.33 460.28 81.67 460.28C70.62 460.28 61.67 451.32 61.67 440.28C61.67 386.42 61.67 244.85 61.67 190.99C61.67 179.95 70.62 170.99 81.67 170.99C193.33 170.99 508.33 170.99 620 170.99Z"
            ></path>
            <path
              d="M557.81 193.02C568.16 193.02 576.55 201.4 576.55 211.75C576.55 256.1 576.55 370.41 576.55 414.76C576.55 425.1 568.16 433.49 557.81 433.49C462.75 433.49 196.3 433.49 101.24 433.49C90.89 433.49 82.5 425.1 82.5 414.76C82.5 370.41 82.5 256.1 82.5 211.75C82.5 201.4 90.89 193.02 101.24 193.02C196.3 193.02 462.75 193.02 557.81 193.02Z"
              fill="#292f36"
            ></path>
          </svg>
        </span>
        <h3 class="title">Optimization</h3>
      </div>
      <div class="middle"></div>
      <div class="right fade-in-slide-right animate">
        <p class="feature">
          A website has to be optimized for different devices, screen sizes,
          browsers and data speeds. A website must work well on both desktops
          and mobile phones for the best user experience.
        </p>
      </div>
    </div>
    <div class="some-row -right">
      <div class="left fade-in-slide-left animate">
        <span class="icon -access">
          <svg viewBox="0 0 512 512">
            <path d="M256,112a56,56,0,1,1,56-56A56.06,56.06,0,0,1,256,112Z" />
            <path
              d="M432,112.8l-.45.12h0l-.42.13c-1,.28-2,.58-3,.89-18.61,5.46-108.93,30.92-172.56,30.92-59.13,0-141.28-22-167.56-29.47a73.79,73.79,0,0,0-8-2.58c-19-5-32,14.3-32,31.94,0,17.47,15.7,25.79,31.55,31.76v.28l95.22,29.74c9.73,3.73,12.33,7.54,13.6,10.84,4.13,10.59.83,31.56-.34,38.88l-5.8,45L150.05,477.44q-.15.72-.27,1.47l-.23,1.27h0c-2.32,16.15,9.54,31.82,32,31.82,19.6,0,28.25-13.53,32-31.94h0s28-157.57,42-157.57,42.84,157.57,42.84,157.57h0c3.75,18.41,12.4,31.94,32,31.94,22.52,0,34.38-15.74,32-31.94-.21-1.38-.46-2.74-.76-4.06L329,301.27l-5.79-45c-4.19-26.21-.82-34.87.32-36.9a1.09,1.09,0,0,0,.08-.15c1.08-2,6-6.48,17.48-10.79l89.28-31.21a16.9,16.9,0,0,0,1.62-.52c16-6,32-14.3,32-31.93S451,107.81,432,112.8Z"
            />
          </svg>
        </span>
        <h3 class="title">Accessibility</h3>
      </div>
      <div class="middle"></div>
      <div class="right fade-in-slide-right animate">
        <p class="feature">
          A user-friendly website must be accessible to everyone including the
          people with disabilities.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Effective",
};
</script>

<style lang='sass' scoped>
@import '@/assets/_variables.sass'

.effective-info
  width: 100%
  margin-top: 50px
  max-width: 900px

.some-row
  height: 170px
  display: flex
  align-items: center
  overflow: hidden
  margin-bottom: 30px

  @media (max-width: 700px)
    height: auto
    flex-direction: column
    margin-bottom: 60px

  @media (min-width: 1500px)
    height: 200px

  &:hover
    > .left
      > .title
        &:first-letter
          color: $c-bittersweet
      > .icon
        border-color: $c-bittersweet
        > svg
          fill: $c-bittersweet

    > .middle
      transition: $normal-transition
      background: $c-bittersweet

      @media (max-width: 700px)
        display: none

  &.-right
    flex-direction: row-reverse

    @media (max-width: 700px)
      flex-direction: column

    > .middle
      transform: skewX(15deg)

    &:hover
      > .middle
        background: $c-bittersweet

  > .middle
    height: 100%
    width: 3px
    background: $c-apple-green
    transform: skewX(-15deg)
    transition: $normal-transition

  > .left, > .right
    height: 100%
    width: 50%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 0 40px

    @media (max-width: 700px)
      width: 100%

  > .right
    opacity: 0
    visibility: hidden
    transform: translateX(100px)

    > .feature
      font-size: 16px
      text-align: center
      margin: 0
      max-width: 300px

      @media (max-width: 700px)
        margin-top: 30px

  > .left
    opacity: 0
    visibility: hidden
    transform: translateX(-100px)

    > .icon
      padding: 15px
      border: 2px solid $c-medium-turquoise
      border-radius: 50%
      max-width: 70px
      max-height: 70px
      width: 100%
      height: 100%
      transition: $normal-transition

      &.-seo, &.-responsive
        padding: 20px

      &.-access, &.-user
        padding: 18px

      > svg
        height: 100%
        width: 100%
        fill: $c-medium-turquoise
        transition: $normal-transition

    > .title
      font-size: 20px
      font-weight: 500
      text-transform: uppercase
      text-align: center
      margin-bottom: 0

      &:first-letter
        color: $c-medium-turquoise
</style>