<template>
  <div class="services-row">
    <article class="service-block fade-in animate">
      <h3 class="title">Web development</h3>
      <p class="description">
        I build scalable, reliable and effective websites using the latest
        programming standards. I develop custom e-commerce, business and other
        types of websites that enable you to achieve your digital goals.
      </p>
      <h4>What you get</h4>
      <ul class="offering-list">
        <li class="item">1 website</li>
        <li class="item">Multiple revisions</li>
        <li class="item"><strong>EFFECTIVE</strong> website qualities</li>
        <li class="item">Constant communication</li>
        <li class="item">Vue.js or Nuxt.js frontend</li>
        <li class="item">Strapi CMS</li>
        <li class="item">PostgreSQL, MySQL or MongoDb database</li>
        <li class="item">Progressive web app included</li>
        <li class="item">Support after work completion</li>
      </ul>
      <a
        class="primary-button"
        href="#contact"
        @click="$emit('selectField', 'Web development')"
        >Contact</a
      >
    </article>
    <article class="service-block fade-in animate">
      <h3 class="title">Progressive web apps</h3>
      <p class="description">
        If you already have a website I convert it to PWA to make sure your
        content is accessible even when the phone is offline. PWA can be
        installed on a phone and will feel like a native mobile app. Businesses
        that convert their web apps to PWA see a significant increase in sales
        and user/visitor engagement.
      </p>
      <h4>What you get</h4>
      <ul class="offering-list">
        <li class="item">1 PWA</li>
        <li class="item">Design customization</li>
        <li class="item">App submission</li>
        <li class="item">App Icon</li>
        <li class="item">A splash screen</li>
        <li class="item">Android and iOS integration</li>
        <li class="item">Multiple revisions</li>
      </ul>
      <a
        class="primary-button -reverse"
        href="#contact"
        @click="$emit('selectField', 'Progressive web apps')"
        >Contact</a
      >
    </article>
    <article class="service-block fade-in animate">
      <h3 class="title">Web Hosting</h3>
      <p class="description">
        I can host your website on a server. Server is secured and monitored
        with daily backups to keep your server running and website accessible
        24/7.
      </p>
      <h4>What you get</h4>
      <ul class="offering-list">
        <li class="item">
          Shared or Dedicated Linux Server provided by Linode
        </li>
        <li class="item">Nginx web server</li>
        <li class="item">Daily backups</li>
        <li class="item">Constant monitoring</li>
        <li class="item">Cloudflare CDN</li>
        <li class="item">Best security practices applied</li>
      </ul>
      <a
        class="primary-button"
        href="#contact"
        @click="$emit('selectField', 'Web hosting')"
        >Contact</a
      >
    </article>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>

<style lang='sass' scoped>
@import '@/assets/_variables.sass'

.services-row
  display: flex
  margin: 50px auto
  width: 100%

  @media (max-width: 875px)
    flex-direction: column

.service-block
  flex: 1 1 33.33%
  padding: 20px
  background: white
  text-align: center
  box-shadow: 0 0 27px 0 rgb(41 41 41 / 9%)
  border-top: 3px solid $c-bittersweet
  display: flex
  flex-direction: column
  opacity: 0
  visibility: hidden

  @media (max-width: 875px)
    flex: 1 1 100%
    margin: 0 auto 60px
    max-width: 450px

  &:nth-of-type(1)
    @media (min-width: 876px)
      padding-right: 40px

  &:nth-of-type(3)
    @media (min-width: 876px)
      padding-left: 40px

  &:nth-of-type(2)
    background: $c-bittersweet
    color: white
    width: 100%

    @media (min-width: 876px)
      z-index: 2
      position: relative
      width: auto

      &::before
        content: ''
        position: absolute
        top: -20px
        right: -20px
        bottom: -20px
        left: -20px
        background: inherit
        pointer-events: none
        z-index: -1

  > .title
    text-align: center
    line-height: 1.3
    min-height: 62px

    @media (max-width: 1182px)
      min-height: 80px

    @media (max-width: 930px)
      min-height: 95px

    @media (max-width: 875px)
      min-height: auto
      margin-bottom: 30px

  > .primary-button
    margin: auto auto 20px
    display: block
    max-width: 200px
    border-color: $c-medium-turquoise
    color: $c-medium-turquoise

    &:hover
      background: $c-medium-turquoise
      color: white

    &.-reverse
      border-color: white
      color: white

      &:hover
        background: white
        color: $c-bittersweet

  > .description
    margin-top: 0

  > .offering-list
    list-style: none
    margin: 20px auto 40px
    text-align: left

    > .item
      padding-left: 20px
      margin-bottom: 5px
      &::first-letter
        font-weight: 700
</style>