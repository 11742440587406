<template>
  <transition-group class="portfolio-list" name="projects" tag="div">
    <section
      v-for="(item, index) in projects"
      :key="index"
      class="portfolio-card animate fade-in-slide-up"
    >
      <div class="card">
        <div class="img-container">
          <lazy-component>
            <picture>
              <source :srcset="srcsetUrl('webp', item)" type="image/webp" />
              <source :srcset="srcsetUrl('png', item)" />
              <img
                class="image"
                :src="item.images.original"
                :alt="item.images.alt"
              />
            </picture>
          </lazy-component>
        </div>
        <ul class="skills-list">
          <li class="skill" v-for="(skill, index) in item.skills" :key="index">
            {{ skill }}
          </li>
        </ul>
        <button
          type="button"
          class="primary-button"
          @click="$emit('showProject', item.project_key)"
        >
          View more
        </button>
      </div>
    </section>
  </transition-group>
</template>


<script>
export default {
  name: "Cards",
  data() {
    return {
      observer: "",
      projects: [
        {
          project_key: "spinneys",
          images: {
            original: require("@/assets/img/spinneys-original-1920.png"),
            alt: "Spinney's website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/spinneys-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/spinneys-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/spinneys-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/spinneys-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/spinneys-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/spinneys-original-480.png"),
                size: 480,
              },
            },
          },
          skills: [
            "frontend",
            "html",
            "css/scss",
            "js/ES6",
            "jQuery",
            "wagtail",
          ],
        },
        {
          project_key: "choithrams",
          images: {
            original: require("@/assets/img/choithrams-original-1920.png"),
            alt: "Choithrams website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/choithrams-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/choithrams-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/choithrams-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/choithrams-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/choithrams-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/choithrams-original-480.png"),
                size: 480,
              },
            },
          },
          skills: ["frontend", "html", "css/scss", "js/ES6", "jQuery"],
        },
        {
          project_key: "galleria",
          images: {
            original: require("@/assets/img/galleria-original-1920.png"),
            alt: "Galleria website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/galleria-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/galleria-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/galleria-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/galleria-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/galleria-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/galleria-original-480.png"),
                size: 480,
              },
            },
          },
          skills: ["frontend", "html", "css/scss"],
        },
        {
          project_key: "lietuvaskaito",
          images: {
            original: require("@/assets/img/lietuvaskaito-original-1920.png"),
            alt: "Lietuva skaito website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/lietuvaskaito-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/lietuvaskaito-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/lietuvaskaito-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/lietuvaskaito-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/lietuvaskaito-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/lietuvaskaito-original-480.png"),
                size: 480,
              },
            },
          },
          skills: [
            "frontend",
            "vue.js",
            "html",
            "css/sass",
            "js/ES6",
            "backend",
            "strapi/node.js",
            "postgres",
          ],
        },
        {
          project_key: "semioad",
          images: {
            original: require("@/assets/img/semioad-original-1920.png"),
            alt: "Semioad website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/semioad-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/semioad-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/semioad-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/semioad-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/semioad-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/semioad-original-480.png"),
                size: 480,
              },
            },
          },
          skills: [
            "frontend",
            "vue.js",
            "html",
            "css/sass",
            "js/ES6",
            "backend",
            "strapi/node.js",
            "postgres",
          ],
        },
        {
          project_key: "autohuh",
          images: {
            original: require("@/assets/img/autohuh-original-1920.png"),
            alt: "Autohuh website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/autohuh-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/autohuh-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/autohuh-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/autohuh-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/autohuh-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/autohuh-original-480.png"),
                size: 480,
              },
            },
          },
          skills: ["frontend", "html", "css/scss", "js/ES6", "wagtail"],
        },
        {
          project_key: "autoamerics",
          images: {
            original: require("@/assets/img/autoamerics-original-1920.png"),
            alt: "Autoamerics website front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/autoamerics-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/autoamerics-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/autoamerics-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/autoamerics-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/autoamerics-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/autoamerics-original-480.png"),
                size: 480,
              },
            },
          },
          skills: ["frontend", "html", "css/scss", "js/ES6", "wagtail"],
        },
        {
          project_key: "fabriqblog",
          images: {
            original: require("@/assets/img/fabriqblog-original-1920.png"),
            alt: "Fabriq Blog section front page screenshot",
            webp: {
              large: {
                url: require("@/assets/img/fabriqblog-original-1920.webp"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/fabriqblog-original-960.webp"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/fabriqblog-original-480.webp"),
                size: 480,
              },
            },
            png: {
              large: {
                url: require("@/assets/img/fabriqblog-original-1920.png"),
                size: 1920,
              },
              medium: {
                url: require("@/assets/img/fabriqblog-original-960.png"),
                size: 960,
              },
              small: {
                url: require("@/assets/img/fabriqblog-original-480.png"),
                size: 480,
              },
            },
          },
          skills: ["frontend", "html", "css/scss", "js/ES6", "wagtail"],
        },
      ],
    };
  },
  methods: {
    animateElements() {
      const options = {
        threshold: 0.5,
      };

      const animatedELements = [].slice.call(
        document.querySelectorAll(".animate")
      );
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const elem = entry.target;
            elem.classList.add("animation-done");
            this.observer.unobserve(elem);
          }
        });
      }, options);

      animatedELements.forEach((animatedELement) =>
        this.observer.observe(animatedELement)
      );
    },
    srcsetUrl(type, item) {
      const format = item.images[type];
      const sizes = Object.keys(format);

      return sizes
        .map((image) => `${format[image].url} ${format[image].size}w`)
        .join(", ");
    },
  },
  mounted() {
    this.animateElements();
  },
  unmounted() {
    this.observer.disconnect();
    this.observer = null;
  },
};
</script>

<style lang='sass'>
@import '@/assets/_variables.sass'
@import '@/assets/_animations.sass'

.portfolio-list
  display: flex
  justify-content: flex-start
  align-items: stretch
  flex-wrap: wrap
  width: 100%
  margin-top: 50px
  overflow: hidden

.portfolio-card
  margin-bottom: 40px
  flex: 0 0 25%
  transition: all .35s ease-in-out
  opacity: 0
  visibility: hidden

  @media (max-width: 1300px)
    flex: 0 0 33.33%

  @media (max-width: 1000px)
    flex: 0 0 50%

  @media (max-width: 600px)
    flex: 0 0 100%

  > .card
    margin: 0 15px
    background-color: white
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 20px
    height: 100%

    &:hover
      > .img-container
        > .image
          transform: scale(1.2)

    > .title
      margin: 0

    > .img-container
      position: relative
      width: 100%
      overflow: hidden
      padding-top: 56.25%

      .image
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        object-fit: cover
        object-position: center
        transition: $normal-transition

    > .skills-list
      list-style: none
      margin: 30px 0 50px

      > .skill
        float: left
        font-size: 14px
        font-weight: 500
        padding: 5px
        margin: 0 5px 5px 0
        color: white
        background: $c-medium-turquoise

        @media (min-width: 1500px)
          font-size: 16px

    > .primary-button
      margin-top: auto

      @media (max-width: 600px)
        margin-top: 40px

.projects-enter
  transform: scale(0.5) translatey(-80px)
  opacity: 0

.projects-leave-to
  transform: translatey(30px)
  opacity: 0

.projects-leave-active
  position: absolute
  z-index: -1
</style>