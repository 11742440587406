<template>
  <div v-if="show_form">
    <div class="contact-message">
      <p class="sliding-text animate fade-in-slide-right">
        I can make your idea a reality, help you with your current project, or
        extend your development team.
      </p>
    </div>
    <form
      class="contact-form animate fade-in-slide-right"
      method="post"
      action=""
      autocomplete="on"
      ref="form"
      @submit.prevent="sendEmail"
    >
      <div class="input-row -short">
        <label class="label" for="name">Your name</label>
        <input
          class="field -input"
          id="name"
          name="name"
          type="text"
          required
        />
      </div>
      <div class="input-row -short">
        <label class="label" for="email">Email</label>
        <input
          class="field -input"
          id="email"
          name="email"
          type="email"
          required
        />
      </div>
      <div class="input-row">
        <label class="label" for="topic">Topic</label>
        <select class="field -input" id="topic" name="topic" required>
          <option value="Contact">Contact</option>
          <option value="Web development">Web development</option>
          <option value="Progressive web apps">Progressive web apps</option>
          <option value="Web hosting">Web hosting</option>
        </select>
      </div>
      <div class="input-row">
        <label class="label" for="description">Project description</label>
        <textarea
          class="field -textarea"
          id="description"
          name="description"
          required
        ></textarea>
      </div>
      <button
        class="primary-button -contact"
        type="submit"
        arial-label="send email"
      >
        Send
      </button>
    </form>

    <p class="contact-submessage animate fade-in-slide-right">
      Or contact me at
      <a class="link" href="mailto: hello@ovidasas.com">hello@ovidasas.com</a>
    </p>
  </div>
  <div v-else class="thank-you">
    <svg class="icon">
      <path
        d="M 91.862889,4.4383237 C 48.27099,86.70242 48.27099,86.70242 48.27099,86.70242 L 5.1194141,58.161817"
        style="
          fill: none;
          stroke: #4ecdc4;
          stroke-width: 18.35099983;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
      />
    </svg>
    <span class="message"
      >Your contact form has been sent. I will respond to it in 24 hours.</span
    >
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  name: "Form",
  data() {
    return {
      service_id: "service_et4s2jf",
      template_id: "contact_form",
      user_id: "user_D0N3hokCjhdzBqIHOIwbH",
      show_form: true,
    };
  },
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          this.service_id,
          this.template_id,
          this.$refs.form,
          this.user_id
        )
        .then(
          () => {
            this.show_form = false;
          },
          () => {
            this.show_form = true;
          }
        );
    },
  },
};
</script>

<style lang='sass' scoped>
@import '@/assets/_variables.sass'

.contact-message
  max-width: 600px
  text-align: center
  margin-bottom: 50px

.contact-form
  max-width: 600px
  display: flex
  flex-wrap: wrap
  opacity: 0
  visibility: hidden
  margin-bottom: 40px

  > .input-row
    margin-bottom: 25px
    display: flex
    flex-direction: column
    width: 100%

    &.-short
      width: calc(50% - 20px)

      @media only screen and (max-width: 550px)
        width: 100%

      &:first-of-type
        margin-right: auto

    > .label
      font-weight: 700
      margin-bottom: 5px

    > .field
      border: none

      &:focus
        outline: 1px solid $c-bittersweet
        box-shadow: 0px 0px 6px 2px rgba(255,107,107,0.75)
        -webkit-box-shadow: 0px 0px 6px 2px rgba(255,107,107,0.75)
        -moz-box-shadow: 0px 0px 6px 2px rgba(255,107,107,0.75)
        border: 0

      &.-input
        height: 30px

      &.-textarea
        height: 100%
        width: 100%
        min-height: 200px
        resize: vertical

.thank-you
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin-top: 50px

  > .icon
    width: 100px
    height: 100px
    margin-bottom: 30px

  > .message
    max-width: 300px

.contact-submessage
  text-align: left
  margin-bottom: 100px
  width: 100%
  max-width: 600px
  margin: 0 auto 50px
  font-weight: 700
  opacity: 0
  visibility: hidden
</style>