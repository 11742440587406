<template>
  <div>
    <Loading v-if="loading" />
    <a class="skip-to-content-link" href="#main">Skip to content </a>
    <Banner />
    <Nav @clicked="fixBody" />
    <main class="main" id="main">
      <section class="full-overlay" :class="{ opened: isOverlay }">
        <div class="project-container">
          <Projects />
          <button
            type="button"
            class="close-button"
            aria-label="Close"
            ref="close_button"
            @click="closeProject"
            @keyup.esc="closeProject"
          >
            &#10006;
          </button>
        </div>
      </section>
      <section class="row -light">
        <div class="container js-observe" id="about">
          <h2 class="heading-title animate fade-in-slide-right">About</h2>
          <About />
        </div>
      </section>
      <section class="row -dark">
        <div class="container">
          <h2 class="heading-title animate fade-in-slide-right">
            What makes websites effective?
          </h2>
          <Effective />
        </div>
      </section>
      <section class="row -light">
        <div class="container js-observe" id="services">
          <h2 class="heading-title animate fade-in-slide-right">Services</h2>
          <Services @select-field="selectField" />
        </div>
      </section>
      <section class="row -dark">
        <div class="container js-observe" id="portfolio">
          <h2 class="heading-title animate fade-in-slide-right">Portfolio</h2>
          <div class="portfolio-container">
            <!-- <div class="categories-list animate fade-in-slide-right">
              <button
                role="button"
                data-filter="all"
                title="filter by all"
                aria-selected="true"
                type="button"
                class="primary-button category active"
                @click="setFilter('ALL')"
              >
                All
              </button>
              <button
                role="button"
                data-filter="vue"
                title="filter by vue.js"
                aria-selected="false"
                type="button"
                class="primary-button category"
                @click="setFilter('VUE')"
              >
                Vue
              </button>
              <button
                role="button"
                data-filter="nuxt"
                title="filter by nuxt"
                aria-selected="false"
                class="primary-button category"
                @click="setFilter('NUXT')"
              >
                Nuxt
              </button>
              <button
                role="button"
                data-filter="strapi"
                title="filter by strapi"
                aria-selected="false"
                class="primary-button category"
                @click="setFilter('STRAPI')"
              >
                Strapi
              </button>
            </div> -->
            <Cards @showProject="showProject" />
          </div>
        </div>
      </section>
      <section class="row -light">
        <div class="container -color-white js-observe" id="contact">
          <h2 class="heading-title animate fade-in-slide-right">
            Get In Touch
          </h2>
          <Form />
        </div>
      </section>
    </main>
    <Footer />
  </div>
</template>

<script>
import Loading from "./components/Loading.vue";
import Nav from "./components/Nav.vue";
import Cards from "./components/Cards.vue";
import Projects from "./components/Projects.vue";
import Footer from "./components/Footer.vue";
import Banner from "./components/Banner.vue";
import About from "./components/About.vue";
import Services from "./components/Services.vue";
import Effective from "./components/Effective.vue";
import Form from "./components/Form.vue";

export default {
  name: "ovidasas",
  components: {
    Loading,
    Nav,
    Cards,
    Projects,
    Footer,
    Banner,
    About,
    Services,
    Effective,
    Form,
  },
  data() {
    return {
      loading: false,
      observer: "",
      isOverlay: false,
      body_fixed: false,
      database: null,
    };
  },
  methods: {
    setFilter(filter) {
      this.currentFilter = filter;
    },

    animateElements() {
      const options = {
        threshold: 1.0,
      };

      const animatedELements = [].slice.call(
        document.querySelectorAll(".animate")
      );
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const elem = entry.target;
            elem.classList.add("animation-done");
            this.observer.unobserve(elem);
          }
        });
      }, options);

      animatedELements.forEach((animatedELement) =>
        this.observer.observe(animatedELement)
      );
    },

    observeNav() {
      const options = {
        threshold: [0.25, 0.75],
      };

      const observed_elements = [].slice.call(
        document.querySelectorAll(".js-observe")
      );
      const menu_items = document.querySelectorAll(".scrollactive");

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const elem = entry.target;
            const elem_inview = elem.id;
            const highlighted = document.querySelector(
              `[data-id='${elem_inview}']`
            );

            menu_items.forEach((el) => el.classList.remove("active"));
            highlighted.classList.add("active");
          }
        });
      }, options);

      observed_elements.forEach((observed_element) =>
        this.observer.observe(observed_element)
      );
    },

    showProject(el) {
      const selected_el = document.querySelector(`[data-project='${el}']`);
      document.querySelector("button.close-button");

      this.isOverlay = true;
      this.fixBody();
      selected_el.classList.add("active");
    },

    closeProject() {
      const all_elems = document.querySelectorAll("article.project-info");

      this.isOverlay = false;
      this.fixBody();
      all_elems.forEach((el) => el.classList.remove("active"));
    },

    fixBody() {
      this.body_fixed = !this.body_fixed;
      this.body_fixed
        ? document.body.classList.add("fixed")
        : document.body.classList.remove("fixed");
    },

    selectField(el) {
      const selected_box = document.getElementById("topic");
      selected_box.value = el;
    },
  },

  mounted() {
    this.animateElements();
    this.observeNav();
  },

  unmounted() {
    this.observer.disconnect();
    this.observer = null;
  },
};
</script>

<style lang='sass'>
// Fonts
@font-face
  font-family: "Raleway"
  src: url("~@/assets/fonts/Raleway-VariableFont_wght.ttf") format("truetype-variations")
  font-weight: 400 700
  font-display: swap

@font-face
  font-family: "Arial"
  src: local("Arial")
  font-weight: 400 700
  advance-override: 10
  ascent-override: 94%
  descent-override: 23%
  line-gap-override: 0

// Normalize
@import 'normalize.css/normalize'
@import '@/assets/_variables.sass'
@import '@/assets/_animations.sass'

*
  box-sizing: border-box

// Typography
html, body
  // CHOOSE FONT AND DELETE ONE
  font-family: Raleway, Arial, sans-serif
  font-size: 16px
  line-height: 1.5
  scroll-behavior: smooth

h1,
.h1
  font-size: 3rem
  margin-top: 1.5rem
  margin-bottom: 2rem

h2,
.h2
  font-size: 2rem
  margin-top: 1.5rem
  margin-bottom: 1rem

h3,
.h3
  font-size: 1.5rem
  margin-top: 1.5rem
  margin-bottom: 1rem

h4,
.h4
  font-size: 1.25rem
  margin-top: 1rem
  margin-bottom: 0.75rem

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4
  font-weight: 700
  line-height: 1
  text-transform: uppercase

a
  color: inherit
  text-decoration: none

p
  line-height: 1.5

ul
  padding: 0
  margin: 0

// Custom css
body
  color: $c-gunmetal

.main
  overflow: hidden

.fade-leave-active
  transition: all 0.3s ease

.fade-leave-to
  transform: translateX(-100vw)

.fixed
  overflow: hidden

.row
  width: 100%
  padding: 0 5%
  background-color: $c-gunmetal

  &.-dark
    color: $c-apple-green

  &.-light
    background-color: $c-apple-green

.banner-overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.8)

.container
  min-height: 100vh
  width: 100%
  max-width: 1400px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  margin: 0 auto
  padding: 50px 0
  position: relative
  transition: $normal-transition

  .heading-title
    padding: 10px 0
    border-top: 2px solid
    border-bottom: 2px solid
    margin-bottom: 50px
    opacity: 0
    visibility: hidden
    text-align: center

  .sliding-text
    opacity: 0
    visibility: hidden

.full-overlay
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  display: none
  background-color: rgba(0, 0, 0, 0.7)
  z-index: 6

  &.opened
    display: flex
    justify-content: center
    align-items: center

.primary-button
  border: 2px solid $c-bittersweet
  padding: 10px 20px
  text-align: center
  transition: $normal-transition
  color: $c-bittersweet
  font-size: 16px
  font-weight: 700
  cursor: pointer
  background: transparent

  &:hover
    background: $c-bittersweet
    color: white

  &:focus
    outline: 0
    background: $c-bittersweet
    color: white

  &.-contact
    min-width: 200px

    @media only screen and (max-width: 550px)
      min-width: 100%

.link
  color: $c-bittersweet

.portfolio-container
  display: flex
  flex-direction: column
  width: 100%
  align-items: center

.categories-list
  display: flex
  flex-wrap: wrap
  opacity: 0
  visibility: hidden

  @media (max-width: 415px)
    width: 100%
    justify-content: space-between

  > .category
    margin-right: 10px
    color: $c-apple-green
    border: 2px solid $c-apple-green

    @media (max-width: 415px)
      flex: 0 0 45%
      margin-right: 0

      &:nth-of-type(1), &:nth-of-type(2)
        margin-bottom: 20px

    &:last-of-type
      margin-right: 0

    &:hover
      background: $c-medium-turquoise
      border: 2px solid $c-medium-turquoise
      color: white

    &:focus
      outline: 0
      background: $c-medium-turquoise
      border: 2px solid $c-medium-turquoise
      color: white

    &.active
      background: $c-bittersweet
      border: 2px solid $c-bittersweet
      color: white

      &:hover
        background: $c-medium-turquoise
        border: 2px solid $c-medium-turquoise

      &:focus
        background: $c-medium-turquoise
        border: 2px solid $c-medium-turquoise

.project-container
  width: 100%
  max-width: 55vw
  height: 100%
  max-height: calc(70vh - 50px)
  z-index: 5
  margin: 0 auto
  margin-top: 50px
  position: relative

  @media (max-width: 1700px)
    max-width: 65vw
    max-height: calc(75vh - 50px)

  @media (max-width: 1250px)
    max-width: 75vw
    max-height: calc(85vh - 50px)

  @media (max-width: 850px)
    max-width: 80vw
    max-height: calc(75vh - 50px)

.close-button
  position: absolute
  top: -36px
  right: -36px
  width: 37px
  height: 36px
  color: $c-apple-green
  background: transparent
  border: none
  transition: $normal-transition
  padding: 5px
  line-height: 1
  z-index: 10

  @media (max-width: 1250px)
    top: -29px
    right: -28px
    width: 28px
    height: 28px

  @media (max-width: 550px)
    top: -29px
    right: -20px
    width: 28px
    height: 28px

  &:hover
    cursor: pointer
    color: $c-bittersweet
    border-color: $c-bittersweet

.skip-to-content-link
  background: red
  color: white
  font-weight: 700
  padding: 20px
  position: absolute
  transform: translateY(-100%)
  transition: $normal-transition
  z-index: 1
  font-size: 24px

  &:focus
    transform: translateY(0%)
</style>