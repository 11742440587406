<template>
  <footer class="row -dark">
    <div class="social-wrapper">
      <a
        href="https://www.linkedin.com/in/ovidijus-jurevicius-62b22b1b/"
        class="icon"
        title="A link to LinkedIn profile"
      >
        <svg viewBox="0 0 40 40">
          <circle
            cx="20"
            cy="20"
            r="18.5"
            fill="#98ccfd"
            stroke="#4788c7"
            stroke-miterlimit="10"
          />
          <path
            fill="#fff"
            d="M10.5,14.5h4v15h-4V14.5z M12.488,12.5h-0.022c-1.194,0-1.966-0.89-1.966-2.001	c0-1.135,0.796-1.999,2.011-1.999c1.217,0,1.966,0.864,1.989,1.999C14.5,11.61,13.728,12.5,12.488,12.5z M31.5,20	c0-3.038-2.462-5.5-5.5-5.5c-1.862,0-3.505,0.928-4.5,2.344V14.5h-4v15h4v-8c0-1.657,1.343-3,3-3s3,1.343,3,3v8h4	C31.5,29.5,31.5,20.421,31.5,20z"
          />
        </svg>
      </a>
      <a
        href="https://github.com/ovidasas"
        class="icon -github"
        title="A link to GitHub profile"
      >
        <svg viewBox="0 0 48 48">
          <path
            fill="#fff"
            d="M41,24c0,9.4-7.6,17-17,17S7,33.4,7,24S14.6,7,24,7S41,14.6,41,24z"
          />
          <path
            fill="#455a64"
            d="M21 41v-5.5c0-.3.2-.5.5-.5s.5.2.5.5V41h2v-6.5c0-.3.2-.5.5-.5s.5.2.5.5V41h2v-5.5c0-.3.2-.5.5-.5s.5.2.5.5V41h1.8c.2-.3.2-.6.2-1.1V36c0-2.2-1.9-5.2-4.3-5.2h-2.5c-2.3 0-4.3 3.1-4.3 5.2v3.9c0 .4.1.8.2 1.1L21 41 21 41zM40.1 26.4C40.1 26.4 40.1 26.4 40.1 26.4c0 0-1.3-.4-2.4-.4 0 0-.1 0-.1 0-1.1 0-2.9.3-2.9.3-.1 0-.1 0-.1-.1 0-.1 0-.1.1-.1.1 0 2-.3 3.1-.3 1.1 0 2.4.4 2.5.4.1 0 .1.1.1.2C40.2 26.3 40.2 26.4 40.1 26.4zM39.8 27.2C39.8 27.2 39.8 27.2 39.8 27.2c0 0-1.4-.4-2.6-.4-.9 0-3 .2-3.1.2-.1 0-.1 0-.1-.1 0-.1 0-.1.1-.1.1 0 2.2-.2 3.1-.2 1.3 0 2.6.4 2.6.4.1 0 .1.1.1.2C39.9 27.1 39.9 27.2 39.8 27.2zM7.8 26.4c-.1 0-.1 0-.1-.1 0-.1 0-.1.1-.2.8-.2 2.4-.5 3.3-.5.8 0 3.5.2 3.6.2.1 0 .1.1.1.1 0 .1-.1.1-.1.1 0 0-2.7-.2-3.5-.2C10.1 26 8.6 26.2 7.8 26.4 7.8 26.4 7.8 26.4 7.8 26.4zM8.2 27.9c0 0-.1 0-.1-.1 0-.1 0-.1 0-.2.1 0 1.4-.8 2.9-1 1.3-.2 4 .1 4.2.1.1 0 .1.1.1.1 0 .1-.1.1-.1.1 0 0 0 0 0 0 0 0-2.8-.3-4.1-.1C9.6 27.1 8.2 27.9 8.2 27.9 8.2 27.9 8.2 27.9 8.2 27.9z"
          />
          <path
            fill="#455a64"
            d="M14.2,23.5c0-4.4,4.6-8.5,10.3-8.5c5.7,0,10.3,4,10.3,8.5S31.5,31,24.5,31S14.2,27.9,14.2,23.5z"
          />
          <path
            fill="#455a64"
            d="M28.6 16.3c0 0 1.7-2.3 4.8-2.3 1.2 1.2.4 4.8 0 5.8L28.6 16.3zM20.4 16.3c0 0-1.7-2.3-4.8-2.3-1.2 1.2-.4 4.8 0 5.8L20.4 16.3zM20.1 35.9c0 0-2.3 0-2.8 0-1.2 0-2.3-.5-2.8-1.5-.6-1.1-1.1-2.3-2.6-3.3-.3-.2-.1-.4.4-.4.5.1 1.4.2 2.1 1.1.7.9 1.5 2 2.8 2 1.3 0 2.7 0 3.5-.9L20.1 35.9z"
          />
          <path
            fill="#00bcd4"
            d="M24,4C13,4,4,13,4,24s9,20,20,20s20-9,20-20S35,4,24,4z M24,40c-8.8,0-16-7.2-16-16S15.2,8,24,8 s16,7.2,16,16S32.8,40,24,40z"
          />
        </svg>
      </a>
    </div>
    <div class="copyright">OVIDASAS © {{ new Date().getFullYear() }}</div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang='sass' scoped>
@import '@/assets/_variables.sass'

.copyright
  font-weight: 700
  color: $c-apple-green
  text-align: center
  padding-bottom: 20px
  margin-top: 50px

.social-wrapper
  display: flex
  align-items: center
  justify-content: center
  padding-top: 60px

  > .icon
    height: 50px
    width: 50px
    transition: $normal-transition

    &:first-of-type
      margin-right: 30px

    &:hover
      cursor: pointer
      transform: scale(1.2)

    &.-github
      height: 57px
      width: 57px
</style>